<template>
    <h1>Guides</h1>

    <div class="guides">
        <ul>
            <li
                v-for="item in guidesContent"
                :key="item"
                class="guides__category"
            >
                <h2>
                    {{ item.category }}
                </h2>

                <ul>
                    <li
                        v-for="guide in item.guides"
                        :key="guide"
                        class="guides__guide"
                    >
                        <div v-if="guide.first" class="guides__first-title">
                            <h3>
                                {{ guide.first }}
                            </h3>

                            <svg-icon :icon="`icons/${guide.icon}`" />
                        </div>

                        <router-link :to="`/guide/${item.category.toLowerCase()}/${guide.to}`">
                            {{ guide.title }}
                        </router-link>

                        <svg-icon v-if="!guide.hasFirst" :icon="`icons/${guide.icon}`" />
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
    name: 'Guides',
    components: {
        SvgIcon,
    },
    computed: {
        guidesContent() {
            return this.$i18n.messages.guides.guides
        },
    },
}

</script>

<style lang="scss">
.guides {
    ul, li {
        list-style-type: none;
    }

    ul {
        padding: 0;
    }

    &__category {
        margin-bottom: 24px;
        padding: 12px 16px;
        border-radius: 6px;
        background-color: #174768;

        a {
            margin-right: 6px;
            color: #2ec6ff;
            font-size: 13px;

            @media (min-width: 375px) {
                font-size: 14px;
            }
        }

        .svg-container {
            font-size: 16px;
        }
    }

    &__first-title {
        display: flex;
        margin: 18px 0 6px;

        h3 {
            margin-top: auto;
            margin-right: 6px;
        }
    }

    &__guide {
        margin-bottom: 6px;
    }
}
</style>
